<template>
  <div>
    <notifications group="notification" position="top right" />
    <div class="card card-custom gutter-b">
      <div class="card-header border-0 py-5">
        <h3 class="card-title align-items-center">
          <span class="card-label font-weight-bolder text-dark">
            Devices Critical History
          </span>
        </h3>
      </div>

      <div class="card-body py-0">
        <div class="row">
          <div class="col-md-12">
            <div v-if="isDevicesLoaded" class="table-responsive">
              <table
                class="table table-head-custom table-vertical-center table-head-bg"
              >
                <thead>
                  <tr class="text-uppercase">
                    <th style="min-width: 120px" class="pl-6">Name</th>
                    <th>{{ $t('GENERAL.LOCATION') }}</th>
                    <th>Tags</th>
                    <th style="min-width: 120px">Imei</th>
                    <th>
                      <span
                        @click="orderShadowDevicesByHoursDifference"
                        class="cursor-pointer"
                      >
                        <span
                          class="pt-2"
                          :class="{
                            'text-primary': hoursDifferenceOrder.isActive,
                          }"
                        >
                          Total critical hours</span
                        >
                        <span
                          class="svg-icon svg-icon-sm pl-2"
                          :class="{
                            'svg-icon-primary': hoursDifferenceOrder.isActive,
                          }"
                        >
                          <inline-svg
                            v-if="hoursDifferenceOrder.query === 'asc'"
                            src="media/svg/icons/Navigation/Down-2.svg"
                          />
                          <inline-svg
                            v-else
                            src="media/svg/icons/Navigation/Up-2.svg"
                          />
                        </span>
                      </span>
                    </th>
                    <th
                      class="text-right pr-6"
                      style="
                        min-width: 240px;
                        max-width: 160px;
                        text-align: right;
                      "
                    >
                      {{ $t('GENERAL.ACTION') }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(criticalDevice, key) in shadowDevices" :key="key">
                    <td class="pl-6" :class="{ 'border-top-0': key === 0 }">
                      <span
                        class="text-dark-75 font-weight-bolder mb-1 font-size-lg"
                        >{{ criticalDevice.device.name }}</span
                      >
                    </td>
                    <td :class="{ 'border-top-0': key === 0 }">
                      <span>{{ criticalDevice.device.location.title }}</span>
                    </td>
                    <td :class="{ 'border-top-0': key === 0 }">
                      <span
                        v-for="tag in criticalDevice.device.tags"
                        :key="tag.id"
                        class="label label-lg label-light-primary label-inline mr-2"
                        >{{ tag.name }}</span
                      >
                    </td>
                    <td :class="{ 'border-top-0': key === 0 }">
                      <span
                        v-if="criticalDevice.device.logger"
                        class="text-muted font-weight-500"
                        >{{ criticalDevice.device.logger.imei }}</span
                      >
                      <span v-else class="text-muted font-weight-500"
                        >No logger</span
                      >
                    </td>
                    <td
                      :class="{ 'border-top-0': key === 0 }"
                      class="text-center"
                    >
                      <span
                        v-if="criticalDevice.hoursDifference / 60 !== 0.5"
                        class="text-dark-75 font-weight-bolder mb-1 font-size-lg"
                        >{{ criticalDevice.hoursDifference / 60 }}h</span
                      >
                      <span
                        v-else
                        class="text-dark-75 font-weight-bolder mb-1 font-size-lg"
                        >30 min</span
                      >
                    </td>
                    <td
                      class="text-right pr-6"
                      :class="{ 'border-top-0': key === 0 }"
                    >
                      <button
                        @click="showModal(criticalDevice.device.logger.imei)"
                        class="btn btn-sm btn-default btn-text-primary btn-hover-primary btn-icon mr-2"
                      >
                        <span class="svg-icon svg-icon-md">
                          <inline-svg
                            src="media/svg/icons/Text/Align-justify.svg"
                          />
                        </span>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div v-else class="col-12 text-center pb-5">
              <b-spinner variant="primary" label="Spinning"></b-spinner>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <b-modal
          id="critical_device_modal"
          size="xl"
          hide-header
          hide-footer
          centered
        >
          <div>
            <button
              class="btn btn-light-primary btn-block mb-3"
              @click="$bvModal.hide('critical_device_modal')"
            >
              Close
            </button>
            <div class="table-responsive mb-0 pb-0">
              <div
                v-if="isCriticalDeviceRecordsLoaded"
                class="table-responsive"
              >
                <table
                  class="table table-head-custom table-vertical-center table-head-bg"
                >
                  <thead>
                    <tr class="text-uppercase">
                      <th style="min-width: 120px" class="pl-6">Name</th>
                      <th>{{ $t('GENERAL.LOCATION') }}</th>
                      <th>Tags</th>
                      <th style="min-width: 120px">Imei</th>
                      <th>Start date</th>
                      <th
                        class="text-right pr-6"
                        style="
                          min-width: 240px;
                          max-width: 160px;
                          text-align: right;
                        "
                      >
                        End date
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(
                        criticalDeviceRecord, key
                      ) in criticalDeviceRecords"
                      :key="key"
                    >
                      <td class="pl-6" :class="{ 'border-top-0': key === 0 }">
                        <span
                          class="text-dark-75 font-weight-bolder mb-1 font-size-lg"
                          >{{ criticalDeviceRecord.device.name }}</span
                        >
                      </td>
                      <td :class="{ 'border-top-0': key === 0 }">
                        <span>{{
                          criticalDeviceRecord.device.location.title
                        }}</span>
                      </td>
                      <td :class="{ 'border-top-0': key === 0 }">
                        <span
                          v-for="tag in criticalDeviceRecord.device.tags"
                          :key="tag.id"
                          class="label label-lg label-light-primary label-inline mr-2"
                          >{{ tag.name }}</span
                        >
                      </td>
                      <td :class="{ 'border-top-0': key === 0 }">
                        <span
                          v-if="criticalDeviceRecord.device.logger"
                          class="text-muted font-weight-500"
                          >{{ criticalDeviceRecord.device.logger.imei }}</span
                        >
                        <span v-else class="text-muted font-weight-500"
                          >No logger</span
                        >
                      </td>
                      <td
                        :class="{ 'border-top-0': key === 0 }"
                        class="text-center"
                      >
                        {{ criticalDeviceRecord.startDate }}
                      </td>
                      <td
                        class="text-right pr-6"
                        :class="{ 'border-top-0': key === 0 }"
                      >
                        {{ criticalDeviceRecord.endDate }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </b-modal>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import { mapGetters } from 'vuex'
import ApiService from '@/core/services/api.service'
import axios from 'axios'

export default {
  name: 'CriticalHistoryList',
  data() {
    return {
      devices: [],
      shadowDevices: [],
      isDevicesLoaded: false,
      nameOrder: {
        query: 'asc',
        isActive: false,
      },
      locationOrder: {
        query: 'asc',
        isActive: false,
      },
      statusOrder: {
        query: 'asc',
        isActive: false,
      },
      serviceModeOrder: {
        query: 'asc',
        isActive: false,
      },
      hoursDifferenceOrder: {
        query: 'desc',
        isActive: true,
      },
      criticalDeviceRecords: [],
      isCriticalDeviceRecordsLoaded: false,
    }
  },
  computed: {
    ...mapGetters([
      'currentUserRole',
      'currentUserCompany',
      'currentUserPersonalInfo',
      'currentUserCompanyModule',
    ]),
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Critical History', route: '' },
    ])

    ApiService.get(
      'device_critical_histories',
      `?device.company=${this.currentUserCompany}&order[createdAt]=desc&pagination=false`
    ).then(({ data }) => {
      this.devices = [...data['hydra:member']]

      this.devices.forEach((device) => {
        const row = this.shadowDevices.findIndex(
          (d) => d.device.logger.imei === device.device.logger.imei
        )
        const col = this.shadowDevices[row]

        if (col === undefined) {
          this.shadowDevices.push({
            startDate: device.startDate,
            endDate: device.endDate,
            hoursDifference: 30,
            device: device.device,
          })
        } else {
          this.shadowDevices[row].device.startDate = device.startDate
          this.shadowDevices[row].device.endDate = device.endDate
          this.shadowDevices[row].device.device = device.device
          this.shadowDevices[row].hoursDifference += 30
        }
      })

      this.orderShadowDevicesByHoursDifference()
      this.isDevicesLoaded = true
    })
  },
  methods: {
    orderDevicesByName() {
      this.nameOrder.isActive = true

      if (this.nameOrder.query === 'asc') this.nameOrder.query = 'desc'
      else this.nameOrder.query = 'asc'

      this.isDevicesLoaded = false
      this.devices = []

      if (
        this.currentUserRole === 'ROLE_ROOT' ||
        this.currentUserRole === 'ROLE_SUPER_ADMIN'
      ) {
        ApiService.get('/devices', `?order[name]=${this.nameOrder.query}`).then(
          ({ data }) => {
            this.devices = [...data['hydra:member']]
            this.isDevicesLoaded = true
          }
        )
      } else {
        ApiService.get(
          '/devices',
          `?company=${this.currentUserCompany.replace(
            '/api/v1/companies/',
            ''
          )}&order[name]=${this.nameOrder.query}`
        ).then(({ data }) => {
          this.devices = [...data['hydra:member']]
          this.isDevicesLoaded = true
        })
      }
    },
    orderDevicesByLocation() {
      this.locationOrder.isActive = true

      if (this.locationOrder.query === 'asc') this.locationOrder.query = 'desc'
      else this.locationOrder.query = 'asc'

      this.isDevicesLoaded = false
      this.devices = []

      if (
        this.currentUserRole === 'ROLE_ROOT' ||
        this.currentUserRole === 'ROLE_SUPER_ADMIN'
      ) {
        ApiService.get(
          '/devices',
          `?order[location.title]=${this.locationOrder.query}`
        ).then(({ data }) => {
          this.devices = [...data['hydra:member']]
          this.isDevicesLoaded = true
        })
      } else {
        ApiService.get(
          '/devices',
          `?company=${this.currentUserCompany.replace(
            '/api/v1/companies/',
            ''
          )}&order[location.title]=${this.locationOrder.query}`
        ).then(({ data }) => {
          this.devices = [...data['hydra:member']]
          this.isDevicesLoaded = true
        })
      }
    },
    orderDevicesByStatus() {
      this.statusOrder.isActive = true

      if (this.statusOrder.query === 'asc') this.statusOrder.query = 'desc'
      else this.statusOrder.query = 'asc'

      this.isDevicesLoaded = false
      this.devices = []

      if (
        this.currentUserRole === 'ROLE_ROOT' ||
        this.currentUserRole === 'ROLE_SUPER_ADMIN'
      ) {
        ApiService.get(
          '/devices',
          `?order[status]=${this.locationOrder.query}`
        ).then(({ data }) => {
          this.devices = [...data['hydra:member']]
          this.isDevicesLoaded = true
        })
      } else {
        ApiService.get(
          '/devices',
          `?company=${this.currentUserCompany.replace(
            '/api/v1/companies/',
            ''
          )}&order[status]=${this.locationOrder.query}`
        ).then(({ data }) => {
          this.devices = [...data['hydra:member']]
          this.isDevicesLoaded = true
        })
      }
    },
    orderDevicesByServiceMode() {
      this.serviceModeOrder.isActive = true

      if (this.serviceModeOrder.query === 'asc')
        this.serviceModeOrder.query = 'desc'
      else this.serviceModeOrder.query = 'asc'

      this.isDevicesLoaded = false
      this.devices = []

      if (
        this.currentUserRole === 'ROLE_ROOT' ||
        this.currentUserRole === 'ROLE_SUPER_ADMIN'
      ) {
        ApiService.get(
          '/devices',
          `?order[serviceMode]=${this.locationOrder.query}`
        ).then(({ data }) => {
          this.devices = [...data['hydra:member']]
          this.isDevicesLoaded = true
        })
      } else {
        ApiService.get(
          '/devices',
          `?company=${this.currentUserCompany.replace(
            '/api/v1/companies/',
            ''
          )}&order[serviceMode]=${this.locationOrder.query}`
        ).then(({ data }) => {
          this.devices = [...data['hydra:member']]
          this.isDevicesLoaded = true
        })
      }
    },
    orderShadowDevicesByHoursDifference() {
      this.hoursDifferenceOrder.isActive = true

      if (this.hoursDifferenceOrder.query === 'asc')
        this.hoursDifferenceOrder.query = 'desc'
      else this.hoursDifferenceOrder.query = 'asc'

      if (this.hoursDifferenceOrder.query === 'asc') {
        this.shadowDevices.sort(function (a, b) {
          return b.hoursDifference - a.hoursDifference
        })
        this.hoursDifferenceOrder.query = 'asc'
      } else {
        this.shadowDevices.sort(function (a, b) {
          return a.hoursDifference - b.hoursDifference
        })
        this.hoursDifferenceOrder.query = 'desc'
      }
    },
    showModal(imei) {
      this.criticalDeviceRecords = []
      this.isCriticalDeviceRecordsLoaded = false
      axios
        .get(
          `${process.env.VUE_APP_ROUTE_API_URL}/api/v1/device_critical_histories.jsonld?device.logger.imei=${imei}&order[createdAt]=desc&pagination=false`
        )
        .then(({ data }) => {
          this.criticalDeviceRecords = [...data['hydra:member']]
          this.isCriticalDeviceRecordsLoaded = true
          this.$root.$emit('bv::show::modal', 'critical_device_modal')
        })
    },
  },
}
</script>
